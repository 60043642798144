import React, { useState } from 'react';
import { Box } from '../../../components/Box';
import Modal from '../../../components/Modal';
import { useTheme } from 'styled-components';
import CardTitle from '../../../components/CardTitle';
import Typography from '../../../components/Typography';
import { IOrder, useOrders } from '../../../hooks/react-query/useOrders';
import { Show } from '../../../components/Show';
import { StockEmblem } from '../../../components/StockEmblem';
import { FiX } from 'react-icons/fi';
import Button from '../../../components/Button';
import { ValueContainer } from './styles';
import { format } from 'date-fns';
import { useAuth } from '../../../hooks/react-query/useAuth';
import api from '../../../services/api';
import { fetchData } from '../../../services/data';
import { useDispatch } from 'react-redux';
import { WalletTypeInput } from '../../../components/WalletTypeInput';
import { WalletType } from '../../../store/modules/wallet/types';

import * as Styled from './styles';
import { Input, Loading, LoadingComponent } from '../../../components';
import { submitFormFunctionsByType } from '../../../pages/Dashboard/CreateWallet/submitFormsByType';
import { useWallets } from '../../../hooks/react-query/useWallets';

interface CreateWalletModalProps {
  closeModal: () => void;
  isVisible: boolean
}

export const CreateWalletModal = ({ closeModal, isVisible }: CreateWalletModalProps) => {
  const { refetch } = useWallets()

  const [walletType, setWalletType] = useState<WalletType | null>(null)
  const [walletTypeName, setWalletTypeName] = useState('')
  const [walletName, setWalletName] = useState('')

  const [isLoading, setIsLoading] = useState(false);
  const { data: user } = useAuth();

  const dispatch = useDispatch()
  const theme = useTheme()

  const handleCreateWallet = async () => {
    setIsLoading(true)

    const submitFunction = submitFormFunctionsByType[walletTypeName as keyof typeof submitFormFunctionsByType]

    await submitFunction({
      walletName,
    });

    refetch()
    await fetchData({ dispatch });

    setIsLoading(false);
    closeModal();
  }

  const handleCloseModal = () => {
    closeModal()
  }

  return (
    <Modal
      isVisible={isVisible}
      closeModal={() => handleCloseModal()}
    >
      <Box bg={theme.colors.background} flexDirection='column' w={'20%'}>
        <Styled.Heading>
          <h1>Criar Carteira</h1>
          <button
            onClick={() => handleCloseModal()}
          >
            <FiX />
          </button>
        </Styled.Heading>
        <Box flexDirection='column' w={'100%'}>
          <WalletTypeInput
            type={walletType}
            setTypeName={(s) => setWalletTypeName(s)}
          />
          <Box>
            <Styled.AmountContainer>
              <span>Nome da Carteira</span>

              <Input
                callback={(e) => setWalletName(e)}
                value={walletName}
                inputType='secondary'
              />
            </Styled.AmountContainer>
          </Box>
        </Box>

        <Styled.SubmitButtonContainer>
          <Button revision='primary' onClick={handleCreateWallet}>
            {
              isLoading ? (
                <Loading color={theme.colors.background} size={8} />
              ) :
                'Criar Carteira'
            }
          </Button>
        </Styled.SubmitButtonContainer>
      </Box>
    </Modal>
  )
}
