import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import api from '../../services/api'
import { ITesouroWallet } from '../../store/modules/tesouroWallet/types'
import { IWallet, WalletType } from '../../store/modules/wallet/types'


interface useWalletsProp {
  wallet_type?: WalletType
}

export const useWallets = (props?: useWalletsProp) => {
  const refetch = () => {
    stockWalletsQuery.refetch()
    tesouroWalletsQuery.refetch()
  }

  const stockWalletsQuery = useQuery(['stockWallets'], async () => {
    try {
      const response = await api.get<IWallet[]>('/wallet/list');

      return response.data.map(wallet => ({
        ...wallet,
        type: WalletType['Ações, BDRs, ETFs Nacionais'],
        typeName: 'Ações, BDRs, ETFs Nacionais'
      }));
    } catch (err) {
      console.log(`Error getting user wallets: ${err}`)
      return []
    }
  })

  const tesouroWalletsQuery = useQuery(['tesouroWallets'], async () => {
    try {
      const response = await api.get<ITesouroWallet[]>('/tesouro/list');

      return response.data.map(wallet => ({
        ...wallet,
        type: WalletType['Tesouro Direto'],
        typeName: 'Tesouro Direto'
      }));
    } catch (err) {
      console.log(`Error getting user tresures: ${err}`)

      return []
    }
  })

  const data = useMemo(() => {
    const stockWallets = stockWalletsQuery.data?.filter((stockWallet) => {
      if (props?.wallet_type) {
        return stockWallet.type === props?.wallet_type
      }

      return true
    })

    const tesouroWallets = tesouroWalletsQuery.data?.filter((tesouroWallet) => {
      if (props?.wallet_type) {
        return tesouroWallet.type === props?.wallet_type
      }

      return true
    })

    return [
      ...stockWallets ?? [],
      ...tesouroWallets ?? [],
    ]
  }, [stockWalletsQuery, tesouroWalletsQuery, props])

  const isLoading = stockWalletsQuery.isLoading || tesouroWalletsQuery.isLoading
  const isFetching = stockWalletsQuery.isFetching || tesouroWalletsQuery.isFetching
  const isError = stockWalletsQuery.isError || tesouroWalletsQuery.isError

  return {
    data,
    isLoading,
    isFetching,
    isError,
    refetch,
  }
}
