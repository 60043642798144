import { combineReducers } from 'redux';
import sideBarNavigator from './sideBarNavigator/reducer';
import themeSelector from './themeSelector/reducer';
import userReducer from './user/reducer';
import walletReducer from './wallet/reducer';
import valuationReducer from './valuation/reducer';
import activesReducer from './actives/reducer';
import loadingReducer from './loading/reducer';
import profitReducer from './profit/reducer';
import toastReducer from './toast/reducer';
import tesouroReducer from './tesouroWallet/reducer';
import cryptoReducer from './crypto/reducer';
import uiReducer from './ui/reducer';

export default combineReducers({
  sideBarNavigator,
  themeSelector,
  user: userReducer,
  wallet: walletReducer,
  valuations: valuationReducer,
  actives: activesReducer,
  loading: loadingReducer,
  profit: profitReducer,
  toast: toastReducer,
  tesouro: tesouroReducer,
  ui: uiReducer,
  crypto: cryptoReducer,
})
