import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { IState } from '../../../../store';
import { IWallet } from '../../../../store/modules/wallet/types';

import { animations } from '../../../../resources';


import {
  Container,
  Header,
  Invested,
  TotalPatrimonyContainer,
  VariationContainer,
} from './styles';

import { ITesouroWallet } from '../../../../store/modules/tesouroWallet/types';
import Currency from '../../../../components/Currency';
import Percentage from '../../../../components/Percentage';
import { ImArrowDownRight2, ImArrowUpRight2 } from 'react-icons/im';
import { animate, motion, useMotionValue, useSpring } from 'framer-motion';
import Tooltip from '../../../../components/Tooltip';
import { useTheme } from 'styled-components';
import { ICryptoWallet } from '../../../../store/modules/crypto/types';

const containerAnimation = (delay: number) => ({
  ...animations.containerAnimation,
  mounted: {
    ...animations.containerAnimation.mounted,
    transition: {
      ...animations.containerAnimation.mounted.transition,
      staggerChildren: 0.25,
      delay,
    }
  }
})

interface ActivesInfosProps {
  wallets: IWallet[];
  tesouro_wallets: ITesouroWallet[];
  crypto_wallets: ICryptoWallet[];
}

const TotalInvestedInfos: React.FC<ActivesInfosProps> = ({ wallets, tesouro_wallets, crypto_wallets }) =>  {
  const [patrimonyAnimated, setPatrimonyAnimated] = useState(0);
  const patrimonyRef = useRef(0);

  const theme = useTheme();

  const patrimony = useMemo(() => {
    let total = 0;

    wallets.forEach(wallet => {
      total += wallet.current_wallet_value;
    });

    tesouro_wallets.forEach(tesouroWallet => {
      total += tesouroWallet.current_wallet_value;
    });

    return total;
  }, [wallets, tesouro_wallets]);

  const rentability = useMemo(() => {
    let invested = 0;
    let current_value = 0;
    let yesterday_value = 0;

    wallets.forEach(wallet => {
      current_value += wallet.current_wallet_value;
      invested += wallet.total_wallet_value;
      yesterday_value += wallet.yesterday_wallet_value;
    });

    tesouro_wallets.forEach(tesouroWallet => {
      current_value += tesouroWallet.current_wallet_value;
      invested += tesouroWallet.total_wallet_value;
      yesterday_value += tesouroWallet.yesterday_wallet_value;
    });

    crypto_wallets.forEach(wallet => {
      current_value += wallet.current_wallet_value * 100;
      invested += wallet.total_wallet_value * 100;
      yesterday_value += wallet.yesterday_wallet_value * 100;
    });

    const variation = yesterday_value ? ((current_value - yesterday_value) / yesterday_value) * 1000 : 0

    return {
      percentage: (current_value - invested) / invested * 1000,
      value: (current_value - invested),
      variation,
      total: current_value,
    };
  }, [wallets, tesouro_wallets, crypto_wallets]);

  useEffect(() => {
     const controls = animate(patrimonyRef.current, rentability.total, {
       ease: 'easeOut',
       duration: 0.75,
       onUpdate(value) {
        setPatrimonyAnimated(value);
       }
     });

     patrimonyRef.current = rentability.total;

     return () => controls.stop();
  }, [rentability]);

  return (
    <Container
      variants={containerAnimation(0)}
    >
      <Header
        rentability={rentability.value}
      >
        <TotalPatrimonyContainer
          variants={containerAnimation(0)}
        >
          <h1>
            Patrimônio Total
          </h1>
        </TotalPatrimonyContainer>

        <motion.div
          variants={containerAnimation(0.1)}
        >
          <Currency
            value={rentability.value}
          />
        </motion.div>

        {!!rentability.percentage && (
          <motion.div
            variants={containerAnimation(0.2)}
          >
            <Percentage
              value={rentability.percentage}
            />
          </motion.div>
        )}

        <Tooltip
          title="24h"
          color={rentability.variation > 0 ? theme.colors.success : theme.colors.error}
          textColor={theme.colors.white}
          position="bottom"
        >
          <VariationContainer
            variation={rentability.variation}
            variants={containerAnimation(0.3)}
          >
            {rentability.variation > 0 && '+'}
            <Percentage
              value={rentability.variation}
            />
          </VariationContainer>
        </Tooltip>
      </Header>

      <Invested
        variants={containerAnimation(0.4)}
        rentability={rentability.value}
      >
        <Currency
          value={patrimonyAnimated}
        />
        {rentability.value > 0 ? (
          <ImArrowUpRight2
            size={56}
          />
        ) : (
          <ImArrowDownRight2
            size={56}
          />
        )}
      </Invested>
    </Container>
  );
};

const mapStateToProps = (state: IState) => ({
  wallets: state.wallet.wallets,
  tesouro_wallets: state.tesouro.tesouro_wallets,
  crypto_wallets: state.crypto.crypto_wallets,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TotalInvestedInfos)
