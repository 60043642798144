import { Dispatch } from "redux";
import { fetchActives, fetchBalance, fetchStockInfos, fetchOrders } from "./actives";
import { fetchDividendsMonthlyGraph, fetchProfit, fetchProfitGraph } from "./profit";
import { fetchUser } from "./user"
import { fetchValuations } from "./valuation";
import { fetchWallets } from "./wallet";
import { fetchTesouroWallets } from "./tesouro";
import { fetchCryptoWallets } from "./crypto";

interface IRequest {
  dispatch: Dispatch<any>;
  async?: boolean;
}

export const fetchData = async ({ dispatch, async }: IRequest) => {
  fetchUser(dispatch, async);
  fetchWallets(dispatch, async);
  fetchTesouroWallets(dispatch, async);
  fetchCryptoWallets(dispatch, async);
  fetchValuations(dispatch, async);
  fetchActives(dispatch, async);
  fetchBalance(dispatch, async);
  fetchStockInfos(dispatch, async);
  fetchProfit(dispatch, async);
  fetchProfitGraph(dispatch, async);
  fetchDividendsMonthlyGraph(dispatch, async);
  fetchOrders(dispatch, async);
}
